.container {
  background-color: #272727;
  .innerContainer {
    margin-inline: 80px;
    border-inline: 0.3px solid rgba(235, 235, 235, 0.20);
    padding: 100px 80px;
    .firstRowContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleContainer {
        .subTitle {
          text-transform: uppercase;
          color: #767B82;
          font-family: Rubik;
          font-size: 14px;
          letter-spacing: 2.8px;
          margin-bottom: 20px;
        }
        .title {
          font-family: Rubik;
          line-height: 140%;
          color: white;
          margin-bottom: 70px;
        }
      }
    }
    .benefitsRow {
      gap: 50px;
      justify-content: space-between;
      .container {
        width: 100%;
        .title {
          font-family: Rubik-Medium;
          font-size: 18px;
        }
        .header {
          display: flex;
          align-items: center;
          margin-bottom: 23px;
          .plusIcon {
            z-index: 1;
          }
          .strokeLine {
            z-index: 0;
            height: 0.5px;
            width: 100%;
            background-color: #EBEBEB;
          }
        }
        .contentContainer {
          padding-inline: 12px;
          .title {
            margin-bottom: 12px;
          }
          .description {
            color: #fff;
            font-family: Rubik-Light;
            font-size: 16px;
          }
        }
      }
      .accordionTitle {
        color: #fff;
        font-size: 16px;
        margin-bottom: 0;
      }
      .accordionDesc {
        color: #fff;
        font-size: 16px;
        margin-bottom: 0;
      }
      .askBtn {
        border: 2px solid #4E5452;
        background: transparent;
        color: white;
        position: relative;
        z-index: 0;
        &::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 100%;
          bottom: 0;
          right: 0;
          background: rgba(235,235,235, 0.2);
          transition: left 0.7s ease, border-radius 0.5s ease;
          border-radius: 500px 0 0 100px;
        }
        &:hover {
          color: white;
        }
        &:hover::before {
          left: 0;
          border-radius: 0;
        }
      }
      .description {
        color: white;
        font-family: Rubik-Light;
        font-size: 16px;
      }
      .container {
        width: 100%;
        .title {
          font-family: Rubik-Medium;
          font-size: 18px;
          color: white;
        }
        .header {
          display: flex;
          align-items: center;
          margin-bottom: 23px;
          .plusIcon {
            z-index: 1;
          }
          .strokeLine {
            z-index: 0;
            height: 0.5px;
            width: 100%;
            background-color: #EBEBEB;
          }
        }
        .contentContainer {
          padding-inline: 12px;
          .title {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .container {
    .innerContainer {
      margin-inline: 70px;
      padding: 90px 70px;
      .firstRowContainer {
        .titleContainer {
          .subTitle {
            font-size: 12px;
            margin-bottom: 18px;
          }
          .title {
            font-size: 36px;
            margin-bottom: 60px;
          }
        }
      }
      .benefitsRow {
        gap: 30px;
        .description {
          font-size: 15px;
        }
        .container {
          .title {
            font-family: Rubik-Medium;
            font-size: 17px;
          }
          .header {
            margin-bottom: 20px;
          }
          .contentContainer {
            padding-inline: 12px;
            .title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    .innerContainer {
      margin-inline: 50px;
      padding: 70px 50px;
      .firstRowContainer {
        .titleContainer {
          .subTitle {
            font-size: 10px;
            margin-bottom: 16px;
          }
          .title {
            font-size: 32px;
            margin-bottom: 40px;
          }
        }
      }
      .benefitsRow {
        gap: 25px;
        .description {
          font-size: 14px;
        }
        .container {
          .title {
            font-family: Rubik;
            font-size: 16px;
          }
          .header {
            margin-bottom: 16px;
          }
          .contentContainer {
            padding-inline: 10px;
            .title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    .innerContainer {
      margin-inline: 30px;
      padding: 50px 30px;
      .firstRowContainer {
        .titleContainer {
          .subTitle {
            font-size: 11px;
            margin-bottom: 14px;
          }
          .title {
            font-size: 28px;
            margin-bottom: 40px;
          }
        }
      }
      .benefitsRow {
        gap: 20px;
        .accordionTitle {
          font-size: 14px;
        }
        .accordionDesc {
          font-size: 14px;
        }
        .description {
          font-size: 13px;
        }
        .container {
          .title {
            font-family: Rubik;
            font-size: 15px;
          }
          .header {
            margin-bottom: 14px;
          }
          .contentContainer {
            padding-inline: 10px;
            .title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .innerContainer {
      margin-inline: 17.5px;
      padding: 37.5px 17.5px;
      .firstRowContainer {
        .titleContainer {
          .subTitle {
            font-size: 11px;
            margin-bottom: 12px;
          }
          .title {
            font-size: 20px;
            margin-bottom: 40px;
          }
        }
      }
      .benefitsRow {
        gap: 20px;
        .description {
          font-size: 13px;
        }
        .container {
          .title {
            font-family: Rubik;
            font-size: 14px;
          }
          .header {
            margin-bottom: 14px;
          }
          .contentContainer {
            padding-inline: 10px;
            .title {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 481px) {

}
@media (max-width: 321px) {
  .container {
    .innerContainer {
      margin-inline: 15px;
      padding: 30px 15px;
      .firstRowContainer {
        .titleContainer {
          .subTitle {
            font-size: 8px;
            margin-bottom: 10px;
          }
          .title {
            font-size: 12px;
          }
        }
      }
      .benefitsRow {
        .description {
          font-size: 12px;
        }
        .plusIcon {
          width: 14px;
        }
        .accordionTitle {
          font-size: 10px;
        }
        .accordionDesc {
          font-size: 10px;
        }
        .container {
          .title {
            font-family: Rubik;
            font-size: 12px;
          }
          .header {
            margin-bottom: 12px;
          }
          .contentContainer {
            padding-inline: 10px;
            .title {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
