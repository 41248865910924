.clip {
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 20px;
}
.buttonText {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.35s;
}
.buttonTextBottom {
  bottom: 0%;
}
.clip:hover {
  .buttonText {
    transform: translateY(-50%) rotateX(90deg);
  }
  .buttonTextBottom {
    transform: translateY(-100%) rotateX(0deg);
  }
}
