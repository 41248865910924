.preloader {
    z-index: 100000;
    background-color: #272727;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
.loaderInside {
    width: 100%;
    height: 100%;
    background-color: #000000;
    animation: moveUp 2s 0.7s forwards;
    transform: translateY(100%);
}

.loaderBlock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderText {
    font-size: 8rem;
    line-height: 1;
    display: flex;
    color: white;
}
.letter-1, .letter-2, .letter-3, .letter-4, .letter-5, .letter-6, .letter-7, .letter-8, .letter-9, .letter-10, .letter-11 {
    opacity: 0;
    display: inline-block;
    animation: loadingAnimation 2.5s forwards;
}
.letter-1 { animation-delay: 0s; }
.letter-2 { animation-delay: 0.1s; }
.letter-3 { animation-delay: 0.2s; }
.letter-4 { animation-delay: 0.3s; }
.letter-5 { animation-delay: 0.4s; }
.letter-6 { animation-delay: 0.5s; }
.letter-7 { animation-delay: 0.6s; }
.letter-8 { animation-delay: 0.7s; }
.letter-9 { animation-delay: 0.8s; }
.letter-10 { animation-delay: 0.9s; }
.letter-11 { animation-delay: 1s; }

@keyframes loadingAnimation {
    0% {
        opacity: 0;
        transform: translate3d(0px, 100%, 0px);
    }
    50% {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }
    100% {
        opacity: 0;
        transform: translate3d(0px, -100%, 0px);
    }
}
@keyframes moveUp {
    0% {
        transform: translateY(100%);
    }
    20% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

@media (max-width: 1400px) {
    .loaderText {
        font-size: 7rem;
    }
}
@media (max-width: 1200px) {
    .loaderText {
        font-size: 5rem;
    }
}
@media (max-width: 992px) {
    .loaderText {
        font-size: 4rem;
    }
}
@media (max-width: 768px) {
    .loaderText {
        font-size: 3rem;
    }
}
@media (max-width: 481px) {
    .loaderText {
        font-size: 2rem;
    }
}
@media (max-width: 321px) {
    .loaderText {
        font-size: 1.5rem;
    }
}
