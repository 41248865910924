.input {
  margin-bottom: 5px;
  letter-spacing: 0.39px;
  padding-bottom: 31px;
  &.darkContainer {
    border-bottom: 1px solid rgba(235, 235, 235, 0.20);
    &:hover, &:focus {
      border-color: white;
    }
  }
  &.lightContainer {
    border-bottom: 1px solid #EBEBEB;
    &:hover, &:focus {
      border-color: black;
    }
  }
}
 @media (max-width: 1400px) {
 }
 @media (max-width: 1200px) {
 }
 @media (max-width: 992px) {
 }
 @media (max-width: 768px) {
   .input {
     margin-bottom: 28px;
     padding-bottom: 15px;
     font-size: 12px;
   }
 }
 @media (max-width: 481px) {
 }
 @media (max-width: 321px) {
 }
