.animatedTypography {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.animatedTypography.visible {
  opacity: 1;
  transform: translateY(0);
}
