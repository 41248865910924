.container {
  margin-inline: 80px;
  padding-block: 100px;
  background-color: white;
  border-inline: 0.3px solid #EBEBEB;
  .contentContainer {
    padding-inline: 80px;
    .subTitle {
      text-transform: uppercase;
      color: #767B82;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 2.8px;
      margin-bottom: 20px;
    }
    .title {
      font-family: Rubik;
      line-height: 140%;
    }
  }
  .imageContainer {
    background-image: url("../../../../assets/webp/scheme-sideImage.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

 @media (max-width: 1400px) {
   .container {
     margin-inline: 70px;
     padding-block: 80px;
     .contentContainer {
       padding-inline: 70px;
       .subTitle {
         font-size: 12px;
         margin-bottom: 18px;
       }
       .title {
         font-size: 36px;
       }
     }
   }
 }
 @media (max-width: 1200px) {
   .container {
     margin-inline: 50px;
     padding-block: 60px;
     .contentContainer {
       padding-inline: 50px;
       .subTitle {
         font-size: 10px;
         margin-bottom: 16px;
       }
       .title {
         font-size: 32px;
       }
     }
   }
 }
 @media (max-width: 992px) {
   .container {
     margin-inline: 30px;
     padding-block: 40px;
     .contentContainer {
       padding-inline: 30px;
       .subTitle {
         font-size: 11px;
         margin-bottom: 14px;
       }
       .title {
         font-size: 28px;
       }
     }
   }
 }
 @media (max-width: 768px) {
   .container {
     margin-inline: 17.5px;
     padding-block: 27.5px;
     .contentContainer {
       padding-inline: 17.5px;
       .subTitle {
         font-size: 11px;
         margin-bottom: 12px;
       }
       .title {
         font-size: 20px;
       }
     }
   }
 }
 @media (max-width: 481px) {
 }
 @media (max-width: 321px) {
   .container {
     margin-inline: 15px;
     padding-block: 25px;
     .contentContainer {
       padding-inline: 15px;
       .subTitle {
         font-size: 8px;
         margin-bottom: 10px;
       }
       .title {
         font-size: 12px;
       }
     }
   }
 }
