.burger {
  width: 25px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  overflow: visible;
}

.burger div {
  background-color: #FFFFFF;
  height: 2px;
  transition: all 0.3s;
  transform-origin: center;
}

.burger div:nth-child(1) {
  width: 20%;
}

.burger div:nth-child(2) {
  width: 60%;
}

.burger div:nth-child(3) {
  width: 100%;
}

/* Состояние иконки при открытом меню (transformed) */
.burger.transformed div {
  background-color: #000000;
}

.burger.transformed div:nth-child(1) {
  width: 100%;
  transform: rotate(45deg) translate(5px, 5px);
}

.burger.transformed div:nth-child(2) {
  opacity: 0;
  width: 100%;
}

.burger.transformed div:nth-child(3) {
  width: 100%;
  transform: rotate(-45deg) translate(5px, -5px);
}
