.send-modal-custom .ant-modal-content {
    background-color: #272727;
    position: relative;
    padding: 0;
    border-radius: 0;
    overflow: hidden;
}
.send-modal-custom .ant-modal-body {
}
.modal-content-container {
    padding: 40px;
}
.modal-content-title {
    font-size: 11px;
}
