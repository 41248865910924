.selectFile_btn {
  background-color: transparent;
  color: white !important;
  border: 1px solid #fff !important;
}
.formFooter {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .formFooter__Text {
    flex-basis: 50%;
    color: #767B82;
    font-family: Rubik;
    font-size: 14px;
  }
  .formFooter__Btn {
    flex-basis: 50%;
    border: 1px solid rgba(235, 235, 235, 0.20) !important;
    background: rgba(255, 255, 255, 0.20) !important;
    color: white !important;
    z-index: 0;
    position: relative;
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      right: 0;
      background: #fff;
      transition: left 0.7s ease, border-radius 0.5s ease;
      border-radius: 500px 0 0 100px;
    }
    &:hover {
      color: black !important;
    }
    &:hover::before {
      left: 0;
      border-radius: 0;
    }
  }
}

@media (max-width: 1400px) {
  .formFooter {
    gap: 15px;
  }
}
@media (max-width: 1200px) {

}
@media (max-width: 992px) {
  .formFooter {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .formFooter__Text {
      flex-basis: 50%;
      color: #767B82;
      font-family: Rubik;
      font-size: 14px;
    }
    .formFooter__Btn {
      flex-basis: 50%;
      border: 1px solid rgba(235, 235, 235, 0.20);
      background: rgba(255, 255, 255, 0.20);
      color: white;
    }
  }
}
@media (max-width: 768px) {
  .formFooter {
    gap: normal;
    flex-direction: column;
    .formFooter__Text {
      flex-basis: auto;
      font-size: 12px;
      text-align: center;
      font-family: Rubik-Light;
    }
    .formFooter__Btn {
      flex-basis: auto;
    }
  }
}
@media (max-width: 481px) {
}
@media (max-width: 321px) {
  .formFooter {
    .formFooter__Text {
      font-size: 10px;
    }
  }
}
