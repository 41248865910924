.container {
  background-color: white;
 .innerContainer {
  margin-inline: 80px;
  padding: 100px 80px;
  border-inline: 0.3px solid #EBEBEB;
  .introduction {
   margin-bottom: 113px;
   .subTitle {
    text-transform: uppercase;
    color: #767B82;
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 2.8px;
    margin-bottom: 20px;
   }
   .title {
    font-family: Rubik;
    line-height: 140%;
   }
   .description {
    color: #272727;
    font-size: 16px;
    letter-spacing: 0.48px;
    margin-bottom: 33px;
    font-family: Rubik-Light;
   }
   .orderManButton {
    align-self: flex-start;
    border: 1px solid #4E5452;
    background: white;
    color: black;
    position: relative;
    overflow: hidden;
    transition: background 0.3s, color 0.3s;
    z-index: 0;
    &::before {
     content: "";
     z-index: -1;
     position: absolute;
     top: 0;
     left: 100%;
     bottom: 0;
     right: 0;
     background: #000;
     transition: left 0.7s ease, border-radius 0.5s ease;
     border-radius: 500px 0 0 100px;
    }
    &:hover {
     color: white;
    }
    &:hover::before {
     left: 0;
     border-radius: 0;
    }
   }
  }
  .benefitsContainer {
   justify-content: space-between;
   .container {
    width: 100%;
    .title {
     font-family: Rubik-Medium;
     font-size: 18px;
    }
    .header {
     display: flex;
     align-items: center;
     margin-bottom: 23px;
     .plusIcon {
      z-index: 1;
     }
     .strokeLine {
      z-index: 0;
      height: 0.5px;
      width: 100%;
      background-color: #EBEBEB;
     }
    }
    .contentContainer {
     padding-inline: 12px;
     .title {
      margin-bottom: 12px;
     }
     .description {
      color: #272727;
      font-family: Rubik-Light;
      font-size: 16px;
     }
    }
   }
   .accordionTitle {
    font-size: 16px;
    margin-bottom: 0;
   }
   .accordionDesc {
    font-size: 16px;
    margin-bottom: 0;
   }
  }
 }
}

 @media (max-width: 1400px) {
  .container {
   .innerContainer {
    margin-inline: 70px;
    padding: 80px 70px;
    .introduction {
     margin-bottom: 80px;
     .subTitle {
      font-size: 12px;
      margin-bottom: 18px;
     }
     .title {
      font-size: 26px;
     }
     .description {
      font-size: 14px;
      letter-spacing: 0.48px;
      margin-bottom: 33px;
     }
    }
   }
  }
 }
 @media (max-width: 1200px) {
  .container {
   .innerContainer {
    margin-inline: 50px;
    padding: 60px 50px;
    .introduction {
     margin-bottom: 80px;
     .subTitle {
      font-size: 10px;
      margin-bottom: 16px;
     }
     .title {
      font-size: 24px;
      font-family: Rubik-Light;
     }
     .description {
      font-size: 12px;
      letter-spacing: 0.34px;
      margin-bottom: 30px;
     }
    }
   }
  }
 }
 @media (max-width: 992px) {
  .container {
   .innerContainer {
    margin-inline: 30px;
    padding: 40px 30px;
    .introduction {
     margin-bottom: 60px;
     .subTitle {
      font-size: 11px;
      margin-bottom: 14px;
     }
     .title {
      font-size: 18px;
     }
     .description {
      font-size: 14px;
      margin-bottom: 15px;
     }
    }
   }
  }
 }
 @media (max-width: 768px) {
  .container {
   .innerContainer {
    margin-inline: 17.5px;
    padding: 30px 17.5px;
    .introduction {
     margin-bottom: 30px;
     .subTitle {
      font-size: 11px;
      margin-bottom: 12px;
     }
     .title {
      font-size: 17px;
     }
     .description {
      font-size: 14px;
      margin-bottom: 15px;
     }
    }
    .benefitsContainer {
     .accordionTitle {
      font-size: 14px;
     }
     .accordionDesc {
      font-size: 14px;
     }
    }
   }
  }
 }
 @media (max-width: 481px) {

 }
 @media (max-width: 321px) {
  .container {
   .innerContainer {
    margin-inline: 15px;
    padding: 25px 15px;
    .introduction {
     margin-bottom: 30px;
     .subTitle {
      font-size: 8px;
      margin-bottom: 10px;
     }
     .title {
      font-size: 14px;
     }
     .description {
      font-size: 10px;
      margin-bottom: 8px;
     }
    }
    .benefitsContainer {
     .plusIcon {
      width: 14px;
     }
     .accordionTitle {
      font-size: 10px;
     }
     .accordionDesc {
      font-size: 10px;
     }
    }
   }
  }
 }
