.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .listItem {
    padding: 50px;
    display: flex;
    border-block: 0.3px solid rgba(235, 235, 235, 0.20);
    gap: 60px;
    align-items: center;
    background-color: #fff;
    z-index: 0;
    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      right: 0;
      background: #F3F3F3;
      transition: left 0.3s ease-out, border-radius 0.3s ease;
      border-radius: 500px 0 0 100px;
    }
    &:hover .itemTitle {
      color: #272727;
    }
    &:hover .itemNumber {
      color: #272727;
    }
    &:hover::before {
      left: 0;
      border-radius: 0;
    }
    .itemNumber {
      font-family: Rubik-Light;
      white-space: nowrap;
      color: #272727;
      font-size: 20px;
      line-height: 140%;
      z-index: 2;
      transition: color 0.2s;
    }
    .itemTitle {
      flex-grow: 1;
      color: #272727;
      font-family: Rubik-Light;
      font-size: 16px;
      z-index: 2;
      transition: color 0.3s;
    }
    .itemImage {
      z-index: 2;
    }
  }
}
 @media (max-width: 1400px) {
   .container .listItem {
     padding: 70px;
     gap: 50px;
     .itemTitle, .itemNumber {
       font-size: 14px;
     }
   }
 }
@media (max-width: 1200px) {
  .container .listItem {
    padding: 50px;
    gap: 40px;
  }
}

@media (max-width: 992px) {
  .container .listItem {
    padding: 30px;
    gap: 50px;
    .itemTitle, .itemNumber {
      font-size: 12px;
    }
  }
}

@media (max-width: 768px) {
  .container .listItem {
    padding: 17.5px;
    gap: 40px;
    .itemTitle, .itemNumber {
      font-size: 12px;
    }
    .itemImage {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 481px) {

}

@media (max-width: 321px) {
  .container .listItem {
    padding: 15px;
    gap: 20px;
    .itemTitle, .itemNumber {
      font-size: 10px;
    }
    .itemImage {
      width: 24px;
      height: 24px;
    }
  }
}
