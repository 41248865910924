.container {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../../assets/webp/banner-image.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .darkOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    flex: 1;
    display: flex;
    flex-direction: column;
    .innerContainer {
      margin-inline: 80px;
      border-inline: 0.3px solid rgba(235, 235, 235, 0.20);
      flex: 1;
      display: flex;
      flex-direction: column;
      .bannerRow {
        padding-inline: 80px;
        margin-top: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        .title {
          text-transform: uppercase;
          font-family: Rubik;
          line-height: 110%;
          color: #AFC538;
          margin-bottom: 18px;
        }
        .subTitle {
          color: #FFF;
          font-family: Rubik-Light;
          font-size: 18px;
          letter-spacing: 0.54px;
          margin-bottom: 40px;
        }
        .orderManButton {
          align-self: flex-start;
          border: 1px solid rgba(235, 235, 235, 0.20);
          background: rgba(255, 255, 255, 0.20);
          color: white;
          margin-bottom: 20px;
          position: relative;
          overflow: hidden;
          transition: background 0.3s, color 0.3s;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 100%;
            bottom: 0;
            right: 0;
            background: #ffffff;
            transition: left 0.7s ease, border-radius 0.5s ease;
            border-radius: 500px 0 0 100px;
          }
          &:hover {
            color: black;
          }
          &:hover::before {
            left: 0;
            border-radius: 0;
          }
        }
      }
      .requisitesRow {
        display: flex;
        align-items: center;
        .requisiteItem {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.3px solid rgba(235, 235, 235, 0.20);
          padding-inline: 10px;
          flex: 1 1 20%;
          .requisiteText {
            color: white;
            font-size: 18px;
            font-family: Rubik-Light;
            letter-spacing: 0.54px;
            text-align: center;
            margin-bottom: 0;
          }
          &.grayBackground {
            background-color: rgba(255, 255, 255, 0.20);
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.20);
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .container {
    .darkOverlay {
      .innerContainer {
        margin-inline: 70px;
        .bannerRow {
          padding-inline: 70px;
          margin-top: 120px;
          .title {
            margin-bottom: 16px;
            font-size: 40px;
          }
          .subTitle {
            font-size: 16px;
            margin-bottom: 30px;
          }
        }
        .requisitesRow {
          .requisiteItem {
            height: 80px;
            padding-inline: 10px;
            .requisiteText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    .darkOverlay {
      .innerContainer {
        margin-inline: 50px;
        .bannerRow {
          padding-inline: 50px;
          margin-top: 110px;
          .title {
            margin-bottom: 14px;
            font-size: 40px;
          }
          .subTitle {
            font-size: 14px;
            margin-bottom: 25px;
          }
        }
        .requisitesRow {
          .requisiteItem {
            height: 80px;
            padding-inline: 10px;
            .requisiteText {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    .darkOverlay {
      .innerContainer {
        margin-inline: 30px;
        .bannerRow {
          padding-inline: 30px;
          margin-top: 80px;
          .title {
            margin-bottom: 9px;
            font-size: 30px;
          }
          .subTitle {
            font-size: 12px;
            margin-bottom: 20px;
            letter-spacing: 0.39px;
          }
        }
        .requisitesRow {
          .requisiteItem {
            height: 60px;
            padding-inline: 10px;
            .requisiteText {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .darkOverlay {
      .innerContainer {
        margin-inline: 17.5px;
        .bannerRow {
          padding-inline: 17.5px;
          margin-top: 60px;
          .title {
            margin-bottom: 5px;
            font-size: 20px;
          }
          .subTitle {
            font-size: 10px;
            margin-bottom: 15px;
          }
        }
        .requisitesRow {
          flex-direction: column;
          .requisiteItem {
            width: 100%;
            padding-inline: 17.5px;
            padding-block: 25px;
            justify-content: flex-start;
            .requisiteText {
              text-align: left;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 481px) {

}
@media (max-width: 321px) {
  .container {
    .darkOverlay {
      .innerContainer {
        margin-inline: 15px;
        .bannerRow {
          padding-inline: 15px;
          .title {
            font-size: 18px;
          }
          .subTitle {
            font-size: 8px;
            margin-bottom: 12px;
          }
        }
        .requisitesRow {
          .requisiteItem {
            padding-inline: 15px;
            padding-block: 15px;
            .requisiteText {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
