.container {
  margin-inline: 80px;
  padding-block: 100px;
  background-color: white;
  border-inline: 0.3px solid #EBEBEB;
  .contentContainer {
    padding-inline: 80px;
    .subTitle {
      text-transform: uppercase;
      color: #767B82;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 2.8px;
      margin-bottom: 20px;
    }
    .title {
      font-family: Rubik;
      line-height: 140%;
    }
  }
  .designerCard {
    width: 100%;
    height: 100%;
    max-width: 600px;
    border: 0.3px solid #EBEBEB;
    display: flex;
    flex-direction: column;
    .imageContainer {
      width: 100%;
      height: 586px;

      & img {
        object-fit: cover;
        object-position: center center;
      }
    }
    .nameContainer {
      padding: 40px;
      flex-grow: 1;
      .subTitle {
        text-transform: uppercase;
        color: #767B82;
        font-size: 14px;
        letter-spacing: 2.8px;
        margin-bottom: 10px;
      }
      .title {
        line-height: 140%;
        margin-bottom: 0;
      }
    }
    .btnContainer {
      padding: 16px 40px;
      display: flex;
      justify-content: space-between;
      border-top: 0.3px solid #EBEBEB;
      cursor: pointer;
      z-index: 0;
      position: relative;
      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        right: 0;
        background: #F3F3F3;
        transition: left 0.7s ease, border-radius 0.5s ease;
        border-radius: 500px 0 0 100px;
      }
      &:hover {
        .text {
          color: #272727;
        }
        .btnIcon svg path {
          stroke: #272727;
        }
      }
      &:hover::before {
        left: 0;
        border-radius: 0;
      }
      .text {
        color: #000;
        z-index: 1;
        transition: color 0.7s;
        font-family: Rubik;
        font-size: 18px;
        letter-spacing: 0.54px;
      }
    }
  }
  .videoSection {
    padding-block: 100px;
    video {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
}

@media (max-width: 1550px) {
  .container {
    .designerCard {
      .imageContainer {
        height: 450px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .container {
    margin-inline: 70px;
    padding-block: 80px;
    .contentContainer {
      padding-inline: 70px;
      .subTitle {
        font-size: 12px;
        margin-bottom: 18px;
      }
      .title {
        font-size: 36px;
      }
    }
    .designerCard {
      .imageContainer {
        height: 400px;
      }
      .nameContainer {
        padding: 18px;
        .subTitle {
          font-size: 12px;
          margin-bottom: 18px;
        }
        .title {
          font-size: 26px;
          margin-bottom: 30px;
        }
      }
      .btnContainer {
        padding: 12px 18px;
        .text {
          font-family: Rubik;
          font-size: 16px;
        }
      }
    }
    .videoSection {
      padding-block: 80px;
      video {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    margin-inline: 50px;
    padding-block: 60px;
    .contentContainer {
      padding-inline: 50px;
      .subTitle {
        font-size: 10px;
        margin-bottom: 16px;
      }
      .title {
        font-size: 32px;
      }
    }
    .designerCard {
      .imageContainer {
        height: 350px;
      }
      .nameContainer {
        padding: 15px;
        .subTitle {
          font-size: 10px;
          margin-bottom: 16px;
        }
        .title {
          font-size: 24px;
          font-family: Rubik-Light;
          margin-bottom: 15px;
        }
      }
      .btnContainer {
        padding: 12px 15px;
        .text {
          font-family: Rubik;
          font-size: 14px;
        }
      }
    }
    .videoSection {
      padding-block: 60px;
      video {
        height: 450px;
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    margin-inline: 30px;
    padding-block: 40px;
    .contentContainer {
      padding-inline: 30px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 14px;
      }
      .title {
        font-size: 28px;
      }
    }
    .designerCard {
      .imageContainer {
        height: 280px;
      }
      .nameContainer {
        padding: 10px;
        .subTitle {
          font-size: 11px;
          margin-bottom: 14px;
        }
        .title {
          font-size: 18px;
        }
      }
      .btnContainer {
        padding: 12px 10px;
        .text {
          font-family: Rubik-Light;
          font-size: 14px;
        }
      }
    }
    .videoSection {
      padding-block: 40px;
      video {
        height: 320px;
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    margin-inline: 17.5px;
    padding-block: 27.5px;
    .contentContainer {
      padding-inline: 17.5px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 12px;
      }
      .title {
        font-size: 20px;
      }
    }
    .designerCard {
      .imageContainer {
        height: 350px;
      }
      .nameContainer {
        padding: 10px;
        .subTitle {
          font-size: 11px;
          margin-bottom: 12px;
        }
        .title {
          font-size: 17px;
        }
      }
      .btnContainer {
        padding: 12px 10px;
        .text {
          font-size: 12px;
        }
        .btnIcon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .videoSection {
      padding-block: 27.5px;
      video {
        height: 250px;
      }
    }
  }
}
@media (max-width: 481px) {
}
@media (max-width: 321px) {
  .container {
    margin-inline: 15px;
    padding-block: 25px;
    .contentContainer {
      padding-inline: 15px;
      .subTitle {
        font-size: 8px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 12px;
      }
    }
    .designerCard {
      .imageContainer {
        height: 250px;
      }
      .nameContainer {
        padding: 10px;
        .subTitle {
          font-size: 8px;
          margin-bottom: 10px;
        }
        .title {
          font-size: 14px;
        }
      }
      .btnContainer {
        padding: 12px 10px;
        .text {
          font-size: 12px;
        }
        .btnIcon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .videoSection {
      padding-block: 25px;
      video {
        height: 160px;
      }
    }
  }
}
