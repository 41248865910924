.container {
  margin-inline: 80px;
  background-color: white;
  .columnContainer {
    width: 100%;
    padding: 100px 80px;
    border: 0.3px solid #EBEBEB;
    display: flex;
    align-items: center;
    .subTitle {
      text-transform: uppercase;
      color: #767B82;
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 2.8px;
      margin-bottom: 20px;
    }
    .title {
      font-family: Rubik;
      line-height: 140%;
    }
    .credentialsContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 44px;
      margin-right: 10px;
      .labelText {
        color: #000;
        font-family: Rubik-Light;
        font-size: 16px;
        white-space: nowrap;
      }
      .valueText {
        color: #000;
        font-family: Rubik-Bold;
        font-size: 18px;
      }
    }
    .formFooter {
      gap: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .formFooter__Text {
        flex-basis: 70%;
        color: #767B82;
        font-family: Rubik;
        font-size: 14px;
      }
      .formFooter__Btn {
        flex-basis: 30%;
        border: 1px solid black;
        background: white;
        color: black;
        z-index: 0;
        &::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 100%;
          bottom: 0;
          right: 0;
          background: #000;
          transition: left 0.7s ease, border-radius 0.5s ease;
          border-radius: 500px 0 0 100px;
        }
        &:hover {
          color: white;
        }
        &:hover::before {
          left: 0;
          border-radius: 0;
        }
      }
    }
  }
  .columnImageBackground {
    width: 100%;
    height: auto;
    border: 0.3px solid #EBEBEB;
  }
  .columnCopyright {
    padding: 42px 80px;
    border: 0.3px solid #EBEBEB;
    .copyRightText {
      color: #767B82;
      font-family: Rubik;
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) {
  .container {
    margin-inline: 70px;
    .columnContainer {
      padding: 80px 70px;
      .subTitle {
        font-size: 12px;
        margin-bottom: 18px;
      }
      .title {
        font-size: 36px;
      }
      .credentialsContainer {
        margin-bottom: 10px;
        margin-right: 10px;
        .labelText {
          font-size: 14px;
        }
        .valueText {
          font-size: 16px;
        }
      }
      .formFooter {
        gap: 15px;
      }
    }
    .columnCopyright {
      padding: 38px 70px;
      .copyRightText {
        font-family: Rubik;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    margin-inline: 50px;
    .columnContainer {
      padding: 60px 50px;
      .subTitle {
        font-size: 10px;
        margin-bottom: 16px;
      }
      .title {
        font-size: 32px;
      }
      .credentialsContainer {
        .labelText {
          font-size: 12px;
        }
        .valueText {
          font-size: 14px;
        }
      }
      .formFooter {
        gap: 15px;
      }
    }
    .columnCopyright {
      padding: 30px 50px;
      .copyRightText {
        font-family: Rubik;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    margin-inline: 30px;
    .columnContainer {
      padding: 40px 30px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 14px;
      }
      .title {
        font-size: 28px;
      }
      .credentialsContainer {
        margin-bottom: 8px;
        .labelText {
          font-size: 11px;
        }
        .valueText {
          font-size: 13px;
        }
      }
      .formFooter {
        gap: 15px;
        .formFooter__Text {
          font-size: 14px;
        }
      }
    }
    .columnImageBackground {
      height: 400px;
    }
  }
}
@media (max-width: 768px) {
  .container {
    margin-inline: 17.5px;
    .columnContainer {
      padding: 27.5px 17.5px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 12px;
      }
      .title {
        font-size: 20px;
      }
      .credentialsContainer {
        .labelText {
          font-size: 10px;
        }
        .valueText {
          font-size: 12px;
        }
      }
      .formFooter {
        gap: normal;
        flex-direction: column;
        .formFooter__Text {
          flex-basis: auto;
          font-size: 12px;
          text-align: center;
          font-family: Rubik-Light;
        }
        .formFooter__Btn {
          flex-basis: auto;
        }
      }
    }
    .columnImageBackground {
      height: 300px;
    }
    .columnCopyright {
      padding: 22px 17.5px;
      .copyRightText {
        font-family: Rubik-Light;
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 481px) {

}
@media (max-width: 321px) {
  .container {
    margin-inline: 15px;
    .columnContainer {
      padding: 25px 15px;
      .subTitle {
        font-size: 8px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 12px;
      }
      .credentialsContainer {
        .labelText {
          font-size: 10px;
        }
        .valueText {
          font-size: 10px;
        }
      }
      .formFooter {
        .formFooter__Text {
          font-size: 10px;
        }
      }
    }
    .columnImageBackground {
      height: 150px;
    }
    .columnCopyright {
      padding: 20px 15px;
      .copyRightText {
        font-size: 8px;
      }
    }
  }
}
