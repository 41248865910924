.container {
  background-color: #272727;
  .innerContainer {
    margin-inline: 80px;
    border-inline: 0.3px solid rgba(235, 235, 235, 0.20);
    padding-top: 80px;
    .contentContainer {
      border-top: 0.3px solid rgba(235, 235, 235, 0.20);
      .uploadContainer {
        width: 100%;
        div {
          display: unset;
        }
      }
      .sideImage {
        width: 100%;
        height: 100%;
        background-image: url("../../../../assets/webp/contact-us-sideImage.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .darkOverlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
          .imageTextContainer {
            position: absolute;
            bottom: 10%;
            left: 0;
            width: 100%;
            padding-inline: 44px;
            display: flex;
            flex-direction: column;
            .imageSubTitle {
              color: #AFC538;
              font-family: Rubik;
              font-size: 14px;
              letter-spacing: 2.8px;
              text-transform: uppercase;
            }
            .imageTitle {
              color: white;
              margin-bottom: 10px;
            }
            .btn {
              border: 1px solid white;
              background: transparent;
              color: white;
              position: relative;
              z-index: 0;
              &::before {
                content: "";
                z-index: -1;
                position: absolute;
                top: 0;
                left: 100%;
                bottom: 0;
                right: 0;
                background: #fff;
                transition: left 0.7s ease, border-radius 0.5s ease;
                border-radius: 500px 0 0 100px;
              }
              &:hover {
                color: black;
              }
              &:hover::before {
                left: 0;
                border-radius: 0;
              }
            }
          }
        }
      }
      .contentColumn {
        padding: 100px 80px;
        .subTitle {
          text-transform: uppercase;
          color: #767B82;
          font-family: Rubik;
          font-size: 14px;
          letter-spacing: 2.8px;
          margin-bottom: 0;
        }
        .title {
          font-family: Rubik;
          line-height: 140%;
          color: white;
          margin-bottom: 44px;
        }
        .formFooter {
          gap: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .formFooter__Text {
            flex-basis: 50%;
            color: #767B82;
            font-family: Rubik;
            font-size: 14px;
          }
          .formFooter__Btn {
            flex-basis: 50%;
            border: 1px solid rgba(235, 235, 235, 0.20);
            background: rgba(255, 255, 255, 0.20);
            color: white;
            z-index: 0;
            position: relative;
            &::before {
              content: "";
              z-index: -1;
              position: absolute;
              top: 0;
              left: 100%;
              bottom: 0;
              right: 0;
              background: #fff;
              transition: left 0.7s ease, border-radius 0.5s ease;
              border-radius: 500px 0 0 100px;
            }
            &:hover {
              color: black;
            }
            &:hover::before {
              left: 0;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
 @media (max-width: 1400px) {
   .container .innerContainer {
     margin-inline: 70px;
     padding-top: 70px;
     .contentContainer {
       .sideImage {
         height: 700px;
         .darkOverlay .imageTextContainer {
           padding-inline: 38px;
           .imageTitle {
             font-size: 26px;
           }
         }
       }
       .contentColumn {
         padding: 90px 70px;
         .subTitle {
           font-size: 12px;
           margin-bottom: 18px;
         }
         .title {
           font-size: 36px;
         }
         .formFooter {
           gap: 15px;
         }
       }
     }
   }
 }
 @media (max-width: 1200px) {
   .container .innerContainer {
     margin-inline: 50px;
     padding-top: 50px;
     .contentContainer {
       .sideImage {
         height: 600px;
         .darkOverlay .imageTextContainer {
           padding-inline: 30px;
           .imageSubTitle {
             font-size: 12px;
           }
           .imageTitle {
             font-size: 24px;
           }
         }
       }
       .contentColumn {
         padding: 70px 50px;
         .subTitle {
           font-size: 10px;
           margin-bottom: 16px;
         }
         .title {
           font-size: 32px;
         }
       }
     }
   }
 }
 @media (max-width: 992px) {
   .container .innerContainer {
     margin-inline: 30px;
     padding-top: 30px;
     .contentContainer {
       .sideImage {
         height: 500px;
         .darkOverlay .imageTextContainer {
           padding-inline: 30px;
           .imageSubTitle {
             font-size: 12px;
             font-family: Rubik-Light;
           }
           .imageTitle {
             font-size: 24px;
           }

         }
       }
       .contentColumn {
         padding: 50px 30px;
         .subTitle {
           font-size: 11px;
           margin-bottom: 14px;
         }
         .title {
           font-size: 28px;
         }
         .formFooter {
           gap: 10px;
           display: flex;
           justify-content: space-between;
           align-items: center;
           .formFooter__Text {
             flex-basis: 50%;
             color: #767B82;
             font-family: Rubik;
             font-size: 14px;
           }
           .formFooter__Btn {
             flex-basis: 50%;
             border: 1px solid rgba(235, 235, 235, 0.20);
             background: rgba(255, 255, 255, 0.20);
             color: white;
           }
         }
       }
     }
   }
 }
 @media (max-width: 768px) {
   .container .innerContainer {
     margin-inline: 17.5px;
     padding-top: 17.5px;
     .contentContainer {
       .sideImage {
         height: 400px;
         .darkOverlay .imageTextContainer {
           padding-inline: 17.5px;
           .imageSubTitle {
             font-size: 10px;
           }
           .imageTitle {
             font-size: 18px;
           }
         }
       }
       .contentColumn {
         padding: 37.5px 17.5px;
         .subTitle {
           font-size: 11px;
           margin-bottom: 12px;
         }
         .title {
           font-size: 20px;
         }
         .formFooter {
           gap: normal;
           flex-direction: column;
           .formFooter__Text {
             flex-basis: auto;
             font-size: 12px;
             text-align: center;
             font-family: Rubik-Light;
           }
           .formFooter__Btn {
             flex-basis: auto;
           }
         }
       }
     }
   }
 }
 @media (max-width: 481px) {
 }
 @media (max-width: 321px) {
   .container .innerContainer {
     margin-inline: 15px;
     padding-top: 15px;
     .contentContainer {
       .sideImage {
         height: 400px;
         .darkOverlay .imageTextContainer {
           padding-inline: 15px;
           .imageSubTitle {
             font-size: 8px;
           }
           .imageTitle {
             font-size: 16px;
           }
         }
       }
       .contentColumn {
         padding: 35px 15px;
         .subTitle {
           font-size: 8px;
           margin-bottom: 10px;
         }
         .title {
           font-size: 12px;
         }
         .formFooter {
           .formFooter__Text {
             font-size: 10px;
           }
         }
       }
     }
   }
 }
