.container {
  margin-inline: 80px;
  padding-top: 100px;
  background-color: white;
  border-inline: 0.3px solid #EBEBEB;
  .contentContainer {
    padding: 100px 80px;
    border-block: 0.3px solid #EBEBEB;
    .subTitle {
      text-transform: uppercase;
      color: #767B82;
      font-size: 14px;
      letter-spacing: 2.8px;
      margin-bottom: 20px;
    }
    .title {
      line-height: 140%;
      margin-bottom: 35px;
    }
    .description {
      color: #272727;
      font-size: 16px;
      letter-spacing: 0.48px;
      font-family: Rubik-Light;
      margin-bottom: 138px;
    }
    .image {}
  }
  .imageContainer {
    background-image: url("../../../../assets/webp/building-image.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .darkOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 1) 100%);
      .imageTextContainer {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        padding-inline: 44px;
        display: flex;
        flex-direction: column;
        .imageSubTitle {
          color: #AFC538;
          font-family: Rubik;
          font-size: 14px;
          letter-spacing: 2.8px;
          text-transform: uppercase;
        }
        .imageTitle {
          color: white;
          margin-bottom: 10px;
        }
        .btn {
          border: 1px solid white;
          background: transparent;
          color: white;
          z-index: 0;
          position: relative;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 100%;
            bottom: 0;
            right: 0;
            background: rgba(235, 235, 235, 0.20);
            transition: left 0.7s ease, border-radius 0.5s ease;
            border-radius: 500px 0 0 100px;
          }
          &:hover {
            color: white;
          }
          &:hover::before {
            left: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .container {
    margin-inline: 70px;
    padding-top: 70px;
    .contentContainer {
      padding: 70px;
      .subTitle {
        font-size: 12px;
        margin-bottom: 18px;
      }
      .title {
        font-size: 26px;
        margin-bottom: 30px;
      }
      .description {
        font-size: 14px;
        letter-spacing: 0.48px;
        margin-bottom: 70px;
      }
      .image {
        width: 84px;
        height: 120px;
      }
    }
    .imageContainer .darkOverlay .imageTextContainer {
      padding-inline: 38px;
      .imageTitle {
        font-size: 26px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    margin-inline: 50px;
    padding-top: 50px;
    .contentContainer {
      padding: 50px;
      .subTitle {
        font-size: 10px;
        margin-bottom: 16px;
      }
      .title {
        font-size: 24px;
        font-family: Rubik-Light;
        margin-bottom: 15px;
      }
      .description {
        font-size: 12px;
        letter-spacing: 0.34px;
        margin-bottom: 40px;
      }
      .image {
        width: 74px;
        height: 110px;
      }
    }
    .imageContainer .darkOverlay .imageTextContainer {
      padding-inline: 30px;
      .imageSubTitle {
        font-size: 12px;
      }
      .imageTitle {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    margin-inline: 30px;
    padding-top: 30px;
    .contentContainer {
      padding: 30px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 14px;
      }
      .title {
        font-size: 18px;
      }
      .description {
        font-size: 14px;
        margin-bottom: 30px;
      }
      .image {
        width: 54px;
        height: 90px;
      }
    }
    .imageContainer .darkOverlay .imageTextContainer {
      padding-inline: 30px;
      .imageSubTitle {
        font-size: 12px;
        font-family: Rubik-Light;
      }
      .imageTitle {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    margin-inline: 17.5px;
    padding-top: 17.5px;
    .contentContainer {
      padding: 17.5px;
      .subTitle {
        font-size: 11px;
        margin-bottom: 12px;
      }
      .title {
        font-size: 17px;
      }
      .description {
        font-size: 14px;
        margin-bottom: 25px;
      }
    }
    .imageContainer .darkOverlay .imageTextContainer {
      padding-inline: 17.5px;
      .imageSubTitle {
        font-size: 10px;
      }
      .imageTitle {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 481px) {
}
@media (max-width: 321px) {
  .container {
    margin-inline: 15px;
    padding-top: 15px;
    .contentContainer {
      padding: 15px;
      .subTitle {
        font-size: 8px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 14px;
      }
      .description {
        font-size: 10px;
        margin-bottom: 20px;
      }
      .image {
        width: 40px;
        height: 70px;
      }
    }
    .imageContainer .darkOverlay .imageTextContainer {
      padding-inline: 15px;
      .imageSubTitle {
        font-size: 8px;
      }
      .imageTitle {
        font-size: 16px;
      }
    }
  }
}
