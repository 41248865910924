.zoomAnimation {
  animation: zoomAnimation 2s ease;
}
.innerCarousel {
  width: 100%;
  position: absolute;
  z-index: -2;
}

.innerSlide {
  width: 100%;
  height: 100vh;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.carousel {
  height: 100vh;
}

.container {
  width: 100%;
  .projectSlide {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .darkOverlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3);
      position: relative;
      .innerContainer {
        height: 100%;
        margin-inline: 80px;
        border-inline: 0.3px solid rgba(235, 235, 235, 0.20);;
        display: flex;
        position: relative;
        z-index: 2;
        .descriptionItem {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.3px solid rgba(235, 235, 235, 0.20);
          flex: auto 1 1;
          .descriptionText {
            color: white;
            font-size: 18px;
            font-family: Rubik-Light;
            letter-spacing: 0.54px;
            text-align: center;
          }
          .titleText {
            color: white;
            font-size: 10px;
            font-family: Rubik-Light;
            white-space: nowrap;
            letter-spacing: 0.3px;
          }
          &.grayBackground {
            background-color: rgba(255, 255, 255, 0.20);
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.20);
          }
        }
        .column1 {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .titleContainer {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            .projectTitle {
              color: white;
              padding-inline: 80px;
              margin-bottom: 14px;
            }
          }
        }
        .column2 {
          display: flex;
          flex-direction: column;
          min-width: 350px;
          cursor: pointer;
          height: 100%;
          justify-content: flex-end;
          border-color: rgba(235, 235, 235, 0.20);
          border-style: solid;
          border-left-width: 0.3px;
          border-right-width: 0.3px;
          position: relative;
          .previewTitle {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 20px;
            .titleText {
              color: white;
              font-size: 16px;
              font-family: Rubik;
              white-space: nowrap;
              letter-spacing: 0.48px;
            }
          }
          .previewImageContainer {
            width: 100%;
            height: 60%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              position: relative;
              //transform: scale(0.5);

              img {
                position: absolute;
                width: auto;
              }
            }
            //img {
            //  transform: scale(2);
            //  object-fit: cover;
            //  transform-origin: top left;
            //}
          }
        }
        .projectDescriptionContainer {
          display: flex;
        }
      }
    }
  }
}

 @media (max-width: 1400px) {
   .container {
     .projectSlide {
       .darkOverlay {
         .innerContainer {
           margin-inline: 70px;
           .descriptionItem {
             padding-inline: 5px;
             .descriptionText {
               font-size: 18px;
             }
           }
           .column1 {
             .titleContainer {
               .projectTitle {
                 padding-inline: 70px;
                 font-size: 36px;
               }
             }
           }
           .column2 {
             min-width: 350px;
             .previewTitle {
               padding: 10px;
             }
           }
         }
       }
     }
   }
 }
 @media (max-width: 1200px) {
   .container {
     .projectSlide {
       .darkOverlay {
         .innerContainer {
           margin-inline: 50px;
           .column1 {
             .titleContainer {
               .projectTitle {
                 padding-inline: 50px;
                 font-size: 32px;
               }
             }
           }
           .column2 {
             min-width: 350px;
           }
         }
       }
     }
   }
 }
 @media (max-width: 992px) {
  .innerSlide {
    height: 450px;
  }
   .container {
     .projectSlide {
       height: 450px;
       .darkOverlay {
         .innerContainer {
           margin-inline: 30px;
           .descriptionItem {
             height: 55px;
             padding-inline: 30px;
             .descriptionText {
               font-size: 16px;
               letter-spacing: 0.4px;
             }
             .titleText {
               font-size: 16px;
               font-family: Rubik;
               letter-spacing: 0.48px;
             }
             &:hover {
               background-color: transparent;
             }
           }
           .column1 {
             .titleContainer {
               .projectTitle {
                 padding-inline: 30px;
                 font-size: 28px;
               }
             }
             .descriptionItem {
               justify-content: flex-start;
             }
           }
           .column2 {
             min-width: 200px;
             .descriptionItem {
               justify-content: center;
             }
           }
         }
       }
     }
   }
 }
 @media (max-width: 768px) {
   .container {
     .projectSlide {
       height: 350px;
       .darkOverlay {
         .innerContainer {
           margin-inline: 17.5px;
           .descriptionItem {
             height: 50px;
             padding-inline: 17.5px;
             .descriptionText {
               font-size: 14px;
             }
             .titleText {
               font-size: 16px;
               font-family: Rubik;
               letter-spacing: 0.48px;
             }
           }
           .column1 {
             .titleContainer {
               .projectTitle {
                 padding-inline: 17.5px;
                 font-size: 20px;
               }
             }
           }
           .column2 {
             min-width: 200px;
           }
         }
       }
     }
   }
 }
 @media (max-width: 481px) {
   .container {
     .projectSlide {
       height: 300px;
       .darkOverlay {
         .innerContainer {
           .descriptionItem {
             height: 40px;
             .descriptionText {
               font-size: 13px;
             }
             .titleText {
               font-size: 14px;
               font-family: Rubik-Light;
               letter-spacing: 0.33px;
             }
           }
           .column1 {
             .titleContainer {
               .projectTitle {
                 font-size: 14px;
               }
             }
           }
           .column2 {
             width: auto;
           }
         }
       }
     }
   }
 }
 @media (max-width: 321px) {
   .container {
     .projectSlide {
       height: 250px;
       .darkOverlay {
         .innerContainer {
           margin-inline: 15px;
           .descriptionItem {
             height: 33px;
             padding-inline: 15px;
             .descriptionText {
               font-size: 10px;
               letter-spacing: 0.33px;
             }
             .titleText {
               font-size: 12px;
             }
           }
           .column1 {
             .titleContainer {
               .projectTitle {
                 padding-inline: 15px;
                 font-size: 12px;
               }
             }
           }
           .column2 {
             width: auto;
           }
         }
       }
     }
   }
 }
@keyframes zoomAnimation {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
