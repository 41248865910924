/* Rubik */
@font-face {
    font-family: 'Rubik';
    src: url('../assets/fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-Black */
@font-face {
    font-family: 'Rubik-Black';
    src: url('../assets/fonts/Rubik-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-BlackItalic */
@font-face {
    font-family: 'Rubik-BlackItalic';
    src: url('../assets/fonts/Rubik-BlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-Bold */
@font-face {
    font-family: 'Rubik-Bold';
    src: url('../assets/fonts/Rubik-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-BoldItalic */
@font-face {
    font-family: 'Rubik-BoldItalic';
    src: url('../assets/fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-ExtraBold */
@font-face {
    font-family: 'Rubik-ExtraBold';
    src: url('../assets/fonts/Rubik-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-ExtraBoldItalic */
@font-face {
    font-family: 'Rubik-ExtraBoldItalic';
    src: url('../assets/fonts/Rubik-ExtraBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-Italic */
@font-face {
    font-family: 'Rubik-Italic';
    src: url('../assets/fonts/Rubik-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-Light */
@font-face {
    font-family: 'Rubik-Light';
    src: url('../assets/fonts/Rubik-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-LightItalic */
@font-face {
    font-family: 'Rubik-LightItalic';
    src: url('../assets/fonts/Rubik-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-Medium */
@font-face {
    font-family: 'Rubik-Medium';
    src: url('../assets/fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-MediumItalic */
@font-face {
    font-family: 'Rubik-MediumItalic';
    src: url('../assets/fonts/Rubik-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Rubik-SemiBold */
@font-face {
    font-family: 'Rubik-SemiBold';
    src: url('../assets/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Rubik-SemiBoldItalic */
@font-face {
    font-family: 'Rubik-SemiBoldItalic';
    src: url('../assets/fonts/Rubik-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
body {
    font-size: 16px;
    background-color: white;
}
html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Трек скроллбара (фон) */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Ползунок скроллбара (перемещаемая часть) */
::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

/* Ползунок при наведении */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.ant-btn {
    letter-spacing: 0.54px;
    font-family: Rubik-Medium;
}
.ant-btn, .ant-input, .ant-input-number, .ant-select,
.ant-btn:focus, .ant-input:focus, .ant-input-number:focus, .ant-select:focus,
.ant-btn:active, .ant-input:active, .ant-input-number:active, .ant-select:active,
.ant-btn:focus-visible, .ant-input:focus-visible, .ant-input-number:focus-visible, .ant-select:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}
.w100 {
    width: 100%;
}


.burger {
    width: 25px;
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    overflow: visible;
}

.burger div {
    background-color: #FFFFFF;
    height: 2px;
    transition: all 0.3s;
    transform-origin: center;
}

.burger div:nth-child(1) {
    width: 20%;
}

.burger div:nth-child(2) {
    width: 60%;
}

.burger div:nth-child(3) {
    width: 100%;
}

/* Состояние иконки при открытом меню (transformed) */
.burger.transformed div {
    background-color: #000000;
}

.burger.transformed div:nth-child(1) {
    width: 100%;
    transform: rotate(45deg) translate(5px, 5px);
}

.burger.transformed div:nth-child(2) {
    opacity: 0;
    width: 100%;
}

.burger.transformed div:nth-child(3) {
    width: 100%;
    transform: rotate(-45deg) translate(5px, -5px);
}
