.burgerIconWrapper {
  position: fixed;
  top: 20px;
  right: 35px;
  z-index: 1001;
  &.visible {
    transform: translateY(0);
    transition: transform 0.25s ease-out;
  }
  &.hidden {
    transform: translateY(-300%);
    transition: transform 0.25s ease-out;
  }
}
.container {
  width: 100%;
  height: 140px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &.visible {
    transform: translateY(0);
    transition: transform 0.25s ease-out, background-color 0.3s ease-in-out;
  }
  &.hidden {
    transform: translateY(-100%);
    transition: transform 0.25s ease-out, background-color 0.3s ease-in-out;
  }
  &.blackBackground {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    height: 94px;
  }
  .innerContainer {
    padding-inline: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.3px solid rgba(235, 235, 235, 0.20);
    .innerContainer__logo {
      margin-right: 20px;
    }
    .navContainer {
      .navItems {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 30px;
        .clip {
          flex-direction: column;
          display: flex;
          position: relative;
          overflow: hidden;
          height: 20px;
          cursor: pointer;
        }
        .buttonText {
          position: relative;
          width: 100%;
          height: 100%;
          transform-style: preserve-3d;
          transition: transform 0.5s;
        }
        .buttonTextBottom {
          bottom: 0%;
        }
        .clip:hover {
          .buttonText {
            transform: translateY(-50%) rotateX(90deg);
          }
          .buttonTextBottom {
            transform: translateY(-100%) rotateX(0deg);
          }
        }
        .navItem {
          color: white;
          font-family: Rubik-Light;
          white-space: nowrap;
        }
      }
      .hamburgerContainer {
        padding: 10px;
        cursor: pointer;
      }
    }
  }
}
.mobileMenu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;
  .mobileMenu__container {
    position: relative;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    .mobileMenu__header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .crossIcon {
        padding: 10px;
        cursor: pointer;
      }
    }
    .mobileMenu__requisitesRow {
      display: flex;
      .mobileMenu__requisitesRow_column {
        flex: 1 1 50%;
        padding: 20px;
        border: 0.3px solid #EBEBEB;
        text-align: center;
        .requisitesRow__column_title {

        }
      }
    }
    .mobileMenu__navItem {
      padding: 20px;
      border-bottom: 0.3px solid #EBEBEB;
    }
  }
}

@media (max-width: 1400px) {
  .container {
    height: 120px;
    .innerContainer {
      padding-inline: 140px;
      .navContainer {
        .navItems {
          gap: 20px;
          .navItem {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    height: 110px;
    .innerContainer {
      padding-inline: 100px;
      .navContainer {
        .navItems {
          gap: 16px;
          .navItem {
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    height: 80px;
    &.blackBackground {
      height: 55px;
    }
    .innerContainer {
      padding-inline: 60px;
      .navContainer {
        .navItems {
          gap: 12px;
          .navItem {
            font-size: 10px;
          }
        }
        .hamburgerContainer {
          padding: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    height: 60px;
    .innerContainer {
      padding-inline: 35px;
      .navContainer {
        .navItems {
          gap: 16px;
          .navItem {
            font-size: 5px;
          }
        }
        .hamburgerContainer {
          padding: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 481px) {

}
@media (max-width: 321px) {
  .mobileMenu__overlay {
    .mobileMenu__container {
      .mobileMenu__header {
        padding: 15px;
        .crossIcon {
          padding: 8px;
        }
      }
      .mobileMenu__requisitesRow {
        .mobileMenu__requisitesRow_column {
          .requisitesRow__column_title {
            font-size: 12px;
          }
        }
      }
      .mobileMenu__navItem {
        padding: 20px;
        .mobileMenu__navItem_title {
          font-size: 12px;
        }
      }
    }
  }
}
