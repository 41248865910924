.image {
  height: 200px !important;
}
.contentContainer {
  padding: 40px;
  .title {
    color: white;
    margin-bottom: 20px;
  }
}
 @media (max-width: 1400px) {
   .contentContainer {
     .title {
       font-size: 26px;
     }
   }
 }
 @media (max-width: 1200px) {
   .contentContainer {
     .title {
       font-size: 24px;
     }
   }
 }
 @media (max-width: 992px) {
   .contentContainer {
     padding: 35px;
     .title {
       font-size: 22px;
     }
   }
 }
 @media (max-width: 768px) {
   .image {
     height: 150px !important;
   }
   .contentContainer {
     padding: 30px;
     .title {
       font-size: 18px;
     }
   }
 }
 @media (max-width: 481px) {
 }
 @media (max-width: 321px) {
   .image {
     height: 100px !important;
   }
   .contentContainer {
     padding: 15px;
     .title {
       font-size: 16px;
     }
   }
 }
